<template lang="pug">
#credits-admin
  b-row
    b-col(cols="12")
      h2 Listado de solicitudes
      p.text-secondary En esta sección prodras administrar todas las solicitudes de financiamiento, asi como tambien. <br> podras contactar y enviar email al cliente para gestionar sus creditos y muchas otras cosas más.
    b-col(lg="6").my-1
      b-form-group
        b-form-input(
          v-model="filter"
          type="search"
          id="filterInput"
          placeholder="Buscar cliente"
        )
          b-input-group-append
            b-button(:disabled="!filter" @click="filter = ''") Clear
    b-col(sm="7" md="6" class="my-1")
      b-pagination(
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="fill"
        size="sm"
        class="my-0"
      )#paginationCredits
    b-col(cols="12")
      b-table(
        bordered
        :items="credits"
        :fields="fields"
        :filter="filter"
        :filter-included-fields="filterOn"
        :current-page="currentPage"
        :per-page="perPage"
        primary-key="id"
        table-variant="light"
      )#tableCredits.table-bordered
        template(v-slot:cell(fullname)="row")
          span {{ row.item.name }} {{ row.item.last_name }}
        template(v-slot:cell(vehicle)="row")
          span {{ row.item.car.brand }} {{ row.item.car.model }} {{ row.item.car.year }}
        template(v-slot:cell(phone)="row")
          a(:href="'tel:'+row.item.phone").d-block.mb-2
            font-awesome-icon(icon="phone")
            span &nbsp;{{ row.item.phone }}
        template(v-slot:cell(email)="row")
          a(:href="'mailto:'+row.item.email").d-block.mb-2
            font-awesome-icon(icon="envelope")
            span &nbsp;{{ row.item.email }}
        template(v-slot:cell(created_at)="row")
          span {{ $moment(row.item.created_at).format("DD/MM/YYYY") }}
</template>
<script>
export default {
  name:'ListCreditAdmin',
  data () {
    return {
      credits: [],
      fields: [
        { key: 'id', label: '#', sortable: true },
        { key: 'fullname', label: 'Nombre', sortable: true },
        { key: 'vehicle', label: 'Vehículo', sortable: true },
        { key: 'phone', label:'Teléfono', sorteable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'created_at', label: 'Ingreso', sortable: true },
      ],
      filter: '',
      filterOn: [],
      perPage: 40,
      totalRows: 1,
      currentPage: 1,
    }
  },
  created() {
    this.getCredits();
  },
  methods: {
    async getCredits() {
      try {
        const { data } = await this.axios.get('/api/v1/credits');
        this.credits = data;
      }
      catch (errors) {
        console.log(errors);
      }
    }
  }
}
</script>
<style lang="scss">
  #credits-admin {
    padding-top: 10px;
  }
  #tableCredits {
    border-radius: 20px;
    overflow: hidden;
  }
  .pagination-sm .page-item:first-child .page-link {
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
  }
  .pagination-sm .page-item:last-child .page-link {
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
  }
  .table-bordered td, .table-bordered th, .table-bordered thead th {
    border: 1px solid #dee2e6;
  }
</style>